import React, { useState } from "react";
import Calendar from "react-calendar";

//styles
import "./CalendarComp.scss";

// const Day = [
//   {
//     name: "January",
//   },
//   {
//     name: "February",
//   },
//   {
//     name: "March",
//   },
//   {
//     name: "April",
//   },
//   {
//     name: "May",
//   },
//   {
//     name: "June",
//   },
//   {
//     name: "July",
//   },
//   {
//     name: "August",
//   },
//   {
//     name: "September",
//   },
//   {
//     name: "October",
//   },
//   {
//     name: "November",
//   },
//   {
//     name: "December",
//   },
// ];

const CalendarComp = () => {
  const [compOneAnime, setCompOneAnime] = useState(false);
  const [date, setDate] = useState(new Date());
  const highlightedDates = [
    new Date(2024, 0, 3),
    new Date(2024, 1, 7),
    new Date(2024, 1, 14),
    new Date(2024, 1, 21),
    new Date(2024, 2, 6),
    new Date(2024, 2, 13),
    new Date(2024, 2, 20),
    new Date(2024, 3, 3),
    new Date(2024, 3, 10),
    new Date(2024, 3, 24),
    new Date(2024, 4, 1),
    new Date(2024, 4, 8),
    new Date(2024, 4, 15),
    new Date(2024, 5, 5),
    new Date(2024, 5, 12),
    new Date(2024, 5, 19),
    new Date(2024, 6, 3),
    new Date(2024, 6, 10),
    new Date(2024, 6, 24),
    new Date(2024, 7, 7),
    new Date(2024, 7, 21),
    new Date(2024, 7, 28),
    new Date(2024, 8, 4),
    new Date(2024, 8, 11),
    new Date(2024, 8, 18),
    new Date(2024, 9, 9),
    new Date(2024, 9, 16),
    new Date(2024, 9, 23),
    new Date(2024, 10, 6),
    new Date(2024, 10, 13),
    new Date(2024, 10, 20),
    new Date(2024, 11, 4),
    new Date(2024, 11, 11),
    new Date(2024, 11, 18),
  ];

  const handleMouseEvent = () => {
    setCompOneAnime((prev) => !prev);
  };
  return (
    <div
      className="calendar-comp-main-container"
      onMouseOver={handleMouseEvent}
      onMouseOut={handleMouseEvent}
    >
      <div className="after-div">
        <div className="bottom-div">
          <div className="calendar-container">
            <Calendar
              onChange={setDate}
              value={date}
              tileClassName={({ date, view }) =>
                view === "month" &&
                highlightedDates.some(
                  (highlightedDate) =>
                    date.getDate() === highlightedDate.getDate() &&
                    date.getMonth() === highlightedDate.getMonth() &&
                    date.getFullYear() === highlightedDate.getFullYear()
                )
                  ? "highlighted"
                  : null
              }
            />
          </div>
          {/* <div className="text-center" >
                        Selected date: {date.toDateString()}
                    </div> */}
        </div>
      </div>

      <div
        className="before-div"
        style={{
          animation: !compOneAnime
            ? "rotateBorder 4s infinite"
            : "rotateBorder 2s infinite",
        }}
      ></div>
    </div>
  );
};

export default CalendarComp;
