import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//styles
import "./AgendaPage.scss";
import { Stickyroll } from "@stickyroll/react";


//assets
const tHubBG =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/t-hub.jpg";
const agriLogo =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/agri-logo.svg";
const vectorPair =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/vector-pair.png";
const arrow =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/arrow.svg";
const waterDropBG =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/water-drop-bg.png";
const progressBar =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/progress-bar.svg";
const greenHemisphere =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/green-hemisphere.png";
const greenKnot =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/green-knot.png";
const timeBG =
  "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/time-bg.png";
const cardBG = "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/timeline-card.png";
// const tHubBG = "https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/t-hub.jpg";

const AgendaPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [pageProgress, setPageProgress] = useState(0);
  const navigate = useNavigate();

  const timelineData = [
    {
      id: 0,
      time: "9:00 AM",
      subtext: "Green Registration & Ice-Breaker",
    },
    {
      id: 1,
      time: "10:00 AM",
      subtext: "Welcome Address",
    },
    {
      id: 2,
      time: "10:10 AM",
      subtext: "Opening Insights",
    },
    {
      id: 3,
      time: "10:20 AM",
      subtext: "Keynote on Global Agri-Food-Tech Trends",
    },
    {
      id: 4,
      time: "10:30 AM",
      subtext: "Interactive Innovation Showcase",
    },
    {
      id: 5,
      time: "11:30 AM",
      subtext: "Networking & Refreshment Break",
    },
  ];

  const topTimelineData = [
    {
      id: 6,
      top: [
        {
          time: "11:40PM - 12:20PM",
          subtext: "Opportunities in Agri-Food-Tech",
          position: "top",
        },
      ],
      bottom: [
        {
          time: "12:00PM - 12:20PM",
          subtext: "Meeting Session 1",
          position: "bottom",
        },
      ],
    },
    {
      id: 7,
      top: [
        {
          time: "12:20PM - 1:00PM",
          subtext: "Challenges in Agri-Food-Tech",
          position: "top",
          bigBG: true,
        },
      ],
      bottom: [
        {
          time: "12:20PM - 12:40PM",
          subtext: "Meeting Session 2",
          position: "bottom",
        },
        {
          time: "12:40PM - 01:00PM",
          subtext: "Meeting Session 3",
          position: "bottom",
        },
      ],
    },
    {
      id: 8,
      top: [
        {
          time: "1:00PM - 1:20PM",
          subtext:
            "The Role of Technology and Collaboration in B2B Partnerships",
          position: "top",
        },
      ],
      bottom: [
        {
          time: "01:00PM - 01:20PM",
          subtext: "Meeting Session 4",
          position: "bottom",
        },
      ],
    },
    {
      id: 9,
      top: [
        {
          time: "1:20PM - 2:00PM",
          subtext:
            "Networking Lunch with Unconferencing - Engaging in small group discussions on specific Agri-Food-Tech topics.",
          position: "top",
        },
      ],
      bottom: [
        {
          time: "1:20PM - 2:00PM",
          subtext: "Networking Lunch",
          position: "bottom",
        },
      ],
    },
    {
      id: 10,
      top: [
        {
          time: "2:00PM - 2:40PM",
          subtext: "Sustainable Farm to Fork Practices",
          position: "top",
        },
      ],
      bottom: [
        {
          time: "2:00PM - 2:40PM",
          subtext: "Meeting Session 5",
          position: "bottom",
        },
      ],
    },
    {
      id: 11,
      top: [
        {
          time: "2:40PM - 3:20PM",
          subtext: "Innovations in Farming Practices",
          position: "top",
          bigBG: true,
        },
      ],
      bottom: [
        {
          time: "2:40PM - 3:00PM",
          subtext: "Meeting Session 6",
          position: "bottom",
        },
        {
          time: "3:00PM - 3:20PM",
          subtext: "Meeting Session 7",
          position: "bottom",
        },
      ],
    },
    {
      id: 12,
      top: [
        {
          time: "3:20PM - 4:00PM",
          subtext: "Supply Chain Innovations",
          position: "top",
          bigBG: true,
        },
      ],
      bottom: [
        {
          time: "3:20PM - 3:40PM",
          subtext: "Meeting Session 8",
          position: "bottom",
        },
        {
          time: "3:40PM - 4:00PM",
          subtext: "Meeting Session 9",
          position: "bottom",
        },
      ],
    },
    {
      id: 13,
      top: [
        {
          time: "4:00PM - 4:20PM",
          subtext: "FireSide Chat Session",
          position: "top",
        },
      ],
      bottom: [
        {
          time: "4:00PM - 4:20PM",
          subtext: "Meeting Session 10",
          position: "bottom",
        },
      ],
    },
    {
      id: 14,
      top: [
        {
          time: "4:20PM - 5:40PM",
          subtext: "Structured Networking",
          position: "top",
        },
      ],
      bottom: [
        {
          time: "4:20PM - 5:40PM",
          subtext: "Agri-Food-Tech Connect Mixer",
          position: "bottom",
        },
      ],
    },
  ];

  const handleButtonClick = () => {
    setIsVisible(!isVisible);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  useEffect(() => {
    const handleScrollEvent = (e) => {
      if (!isVisible) {
        e.preventDefault();
      }
    };
    window.addEventListener("wheel", handleScrollEvent, { passive: false });
    window.addEventListener("touchmove", handleScrollEvent, { passive: false });
    window.addEventListener("keydown", handleScrollEvent, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleScrollEvent);
      window.removeEventListener("touchmove", handleScrollEvent);
      window.removeEventListener("keydown", handleScrollEvent);
    };
  }, [isVisible]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (!isVisible)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  }, [isVisible]);

  return (
    <div className="agenda-page-main-container">
      <Stickyroll
        pages={1}
        current={pageProgress}
        onProgress={(p) => setPageProgress(p)}
        factor={20}
      >
        {/* <p
          style={{
            position: "absolute",
            top: "0",
            color: "yellow",
            zIndex: "10",
          }}
        >
          {pageProgress}
        </p> */}
        <div className="agenda-page-main">
          <div className="agenda-page-main-left">
            <img src={vectorPair} alt="vector-pair" className="vector-pair" />
            <img
              src={agriLogo}
              alt="agri-logo"
              className="agri-logo"
              onClick={handleLogoClick}
            />
            <img
              src={waterDropBG}
              alt="water-drop-bg"
              className="water-drop-bg"
            />
            <div className="agenda-page-main-heading">
              <p className="event-italic-text">Event</p>
              <p className="agenda-text">
                Agenda <hr />
              </p>
              <div className="hyderabad-text-div">
                <img src={arrow} alt="arrow icon" className="arrow-icon" />
                <p className="hyderabad-text">Hyderabad</p>
              </div>
            </div>
            <img src={tHubBG} alt="hyderabad-bg" className="hyderabad-bg" />

            <div
              className="event-date-div"
              style={{
                transform: isVisible ? "translateY(80vh)" : "",
              }}
            >
              <p className="event-date-text">Event Date</p>
              <p className="event-sub-text">April 19th, 2024</p>
            </div>

            <div
              className="theme-div"
              style={{
                transform: isVisible ? "translateY(80vh)" : "",
              }}
            >
              <p className="theme-text">Theme</p>
              <p className="theme-sub-text">
                Cultivate India's Future <br /> A Sustainable 'Farm to Fork'
                Revolution
              </p>
            </div>

            <div
              className="location-div"
              style={{
                transform: isVisible ? "translateY(80vh)" : "",
              }}
            >
              <p className="location-text">Location</p>
              <p className="location-sub-text">T-Hub, Hyderabad, India </p>
            </div>

            <div
              className="time-div"
              style={{
                transform: isVisible ? "translateY(80vh)" : "",
              }}
            >
              <p className="time-text">Time</p>
              <p className="time-sub-text">9:00 AM - 6:00 PM</p>
            </div>

            <div
              className="btn-div"
              style={{
                transform: isVisible ? "translateY(80vh) scale(0.5)" : "",
              }}
            >
              <button onClick={handleButtonClick} className="timeline-btn">
                Event Timeline
              </button>
            </div>
          </div>
        </div>
        <div
          className="timeline-bg"
          onClick={handleButtonClick}
          style={{
            transform: isVisible ? "translateY(0vh)" : "translateY(100vh)",
          }}
        />
        <div
          className="timeline-div-container"
          style={{
            transform: isVisible ? "translateY(0vh)" : "translateY(100vh)",
          }}
        >
          <div className="timeline-div">
            <img
              src={greenHemisphere}
              alt="green-hemisphere"
              className="green-hemisphere"
              style={{
                transform:
                  pageProgress > 0.1
                    ? "translate(-10vw, 0vh) scale(0.8) rotate(50deg)"
                    : "translate(0vw, 0vh)",
                filter: pageProgress > 0.1 ? "blur(4px)" : "blur(0px)",
                opacity: pageProgress > 0.2 ? "0" : "1",
              }}
            />
            <div className="close-btn" onClick={handleButtonClick}>
              &#x292C;
            </div>
            <img
              src={greenKnot}
              alt="green-knot"
              className="green-knot"
              style={{
                transform:
                  pageProgress < 0.1
                    ? "translate(0vw, 0vh)"
                    : pageProgress > 0.1 && pageProgress < 0.2
                      ? "translate(0vw, -30vh) rotate(70deg)"
                      : "translate(-120vw, -30vh)",
              }}
            />
            <img src={timeBG} alt="timeline-background" className="time-bg" />
            <div className="progress-bar-div">
              <img
                src={progressBar}
                alt="progress-bar"
                className="progress-bar"
              />
            </div>
            <div
              className="time-div"
              style={{
                marginLeft:
                  pageProgress < 0.05
                    ? ""
                    : pageProgress >= 0.05 && pageProgress < 0.1
                      ? "-24.5%"
                      : pageProgress >= 0.1 && pageProgress < 0.15
                        ? "-49%"
                        : pageProgress >= 0.15 && pageProgress < 0.2
                          ? "-73.5%"
                          : pageProgress >= 0.2 && pageProgress < 0.25
                            ? "-98%"
                            : pageProgress >= 0.25 && pageProgress < 0.3
                              ? "-122.5%"
                              : pageProgress >= 0.3 && pageProgress < 0.35
                                ? "-158%"
                                : pageProgress >= 0.35 && pageProgress < 0.4
                                  ? "-194.5%"
                                  : pageProgress >= 0.4 && pageProgress < 0.45
                                    ? "-226%"
                                    : pageProgress >= 0.45 && pageProgress < 0.5
                                      ? "-256.5%"
                                      : pageProgress >= 0.5 && pageProgress < 0.55
                                        ? "-280%"
                                        : pageProgress >= 0.55 && pageProgress < 0.6
                                          ? "-303%"
                                          : pageProgress >= 0.6 && pageProgress < 0.65
                                            ? "-332%"
                                            : pageProgress >= 0.65 && pageProgress < 0.7
                                              ? "-368%"
                                              : pageProgress >= 0.7 && pageProgress < 0.75
                                                ? "-397%"
                                                : pageProgress >= 0.75 && pageProgress < 0.8
                                                  ? "-420%"
                                                  : "-445%",
              }}
            >
              {timelineData.map((item, i) => {
                return (
                  <div
                    className={`time-block-div ${item.position === "bottom"
                        ? "bottom"
                        : item.position === "top"
                          ? "top"
                          : ""
                      }`}
                    key={i}
                    style={{
                      filter:
                        Math.floor(pageProgress * 20) === i
                          ? "blur(0px)"
                          : "blur(2px)",
                    }}
                  >
                    <p
                      className={`time-text ${item.position === "bottom" || item.position === "top"
                          ? "card-time-text"
                          : ""
                        }`}
                      style={{
                        color:
                          Math.floor(pageProgress * 20) === i
                            ? "transparent"
                            : "#7B7B7B",
                        transform:
                          item.position === "top" || item.position === "bottom"
                            ? ""
                            : Math.floor(pageProgress * 20) === i
                              ? "translateY(-2vh)"
                              : "",
                        // fontSize:
                        //   Math.floor(pageProgress * 10) === i ? "3.5vw" : "3vw",
                      }}
                    >
                      {item.time}
                    </p>
                    <p
                      className={`sub-text ${item.position === "bottom" || item.position === "top"
                          ? "card-sub-text"
                          : ""
                        }`}
                      style={{
                        color:
                          Math.floor(pageProgress * 20) === i
                            ? "white"
                            : "#7B7B7B",
                        transform:
                          item.position === "top" || item.position === "bottom"
                            ? ""
                            : Math.floor(pageProgress * 20) === i
                              ? "translateY(2vh)"
                              : "",
                        fontSize:
                          item.position === "top" || item.position === "bottom"
                            ? "1vw"
                            : Math.floor(pageProgress * 20) === i
                              ? "1.3vw"
                              : "1.25",
                      }}
                    >
                      {item.subtext}
                    </p>
                  </div>
                );
              })}
              <div className="parallel-heading">
                <p>Conferencing</p>
                <br />
                <br />
                <p>Curated Face-to-Face Meetings</p>
              </div>
              {topTimelineData.map((item, i) => (
                <div
                  className="parallel-timeline-container"
                  style={{
                    filter:
                      Math.floor(pageProgress * 20) === item.id + 1
                        ? "blur(0px)"
                        : "blur(2px)",
                  }}
                >
                  {item.top.map((topItem, j) => (
                    <>
                      <div className="time-block-div top">
                        <img src={cardBG} alt="card-bg" className="card-bg" />
                        <p className="time-text card-time-text">
                          {topItem.time}
                        </p>
                        <p className="sub-text card-sub-text">
                          {topItem.subtext}
                        </p>
                      </div>
                    </>
                  ))}
                  <div className="bottom-parallel-timeline">
                    {item.bottom.map((bottomItem, j) => (
                      <div className="time-block-div bottom" key={j}>
                        <>
                          <img src={cardBG} alt="card-bg" className="card-bg" />

                          <p className="time-text card-time-text">
                            {bottomItem.time}
                          </p>
                          <p className="sub-text card-sub-text">
                            {bottomItem.subtext}
                          </p>
                        </>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <div className="closing-heading">
                <p className="heading">5:40PM - 6:00PM</p>
                <br /> <br />
                <p className="subtext">Closing Session</p>
              </div>
            </div>
          </div>
        </div>
      </Stickyroll>
    </div>
  );
};

export default AgendaPage;
