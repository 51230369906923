import React from 'react';

//styles
import './first-comp.scss';

//images
const vector = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame867.png';
const vector2 = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame869.png';
const AFTLogo = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/agri-food-tech-logo-2.png';
// import bookNowBtn from '../../../imgs/home-page/book-now-btn.png';
// import navVector from '../../../imgs/home-page/Frame872.png';


export default function FirstComp({ FirstCompAnime, PageProgress, Progress }) {

    const progress1 = Progress[0]
    const progress2 = Progress[1]
    const progress3 = Progress[2]

    return (
        <div className="first-comp-main-container"
            style={{
                transform: !FirstCompAnime && PageProgress < progress2 ? 'translateY(100vh)' :
                    FirstCompAnime && PageProgress > progress3 && 'translate(-100vw)'
            }}
        >

            <div className="first-comp-main">

                <div className="vector-one-img-div"
                    style={{
                        transform:
                            FirstCompAnime && PageProgress < progress1 ? 'translate(-16vw, -15vh) rotate(-20deg) scale(0.8)' :
                                FirstCompAnime && PageProgress > progress1 && 'translate(-20vw, -75vh) rotate(-55deg) scale(0.8)',
                        // FirstCompAnime && PageProgress > progress2 && 'translate(-20vw, -20vh) rotate(10deg) scale(0.7)',

                        background: PageProgress > progress2 && '#000'

                    }}
                >
                    <img src={vector} alt="" className="vector-one-img" />
                </div>

                <img src={vector} alt="" className="vector-two-img"
                    style={{
                        transform:
                            FirstCompAnime && PageProgress < progress1 ? 'translate(-30vw, 4vh) rotate(-100deg) scale(0.8)' :
                                FirstCompAnime && PageProgress > progress1 && PageProgress < progress2 ? 'translate(-32vw, -26vh) rotate(-140deg) scale(0.8)' :
                                    FirstCompAnime && PageProgress > progress2 && 'translate(-32vw, -100vh) rotate(-140deg) scale(0.8)',

                        opacity: FirstCompAnime && PageProgress > progress1 && PageProgress < progress2 ? '0.2' :
                            FirstCompAnime && PageProgress > progress2 && '0.15',

                    }}
                />
                <img src={vector} alt="" className="vector-three-img"
                    style={{
                        transform:
                            FirstCompAnime && PageProgress < progress1 ? 'translate(-48vw, 40vh) rotate(-95deg)' :
                                FirstCompAnime && PageProgress > progress1 && PageProgress < progress2 ? 'translate(-50vw, -50vh) rotate(-120deg)' :
                                    FirstCompAnime && PageProgress > progress2 && 'translate(-80vw, -100vh) rotate(-120deg)',

                    }}
                />

                <img src={vector} alt="" className="vector-four-img"
                    style={{
                        transform:
                            FirstCompAnime && PageProgress < progress1 ? 'translate(18vw, 18vh) rotate(-20deg) scale(0.8)' :
                                FirstCompAnime && PageProgress > progress1 && PageProgress < progress2 ? 'translate(18vw, -38vh) rotate(0deg) scale(0.5)' :
                                    FirstCompAnime && PageProgress > progress2 && 'translate(18vw, -100vh) rotate(0deg) scale(0.5)',

                        opacity: FirstCompAnime && PageProgress > progress1 && '0.2',

                        filter: PageProgress > progress2 && 'none'

                    }}
                />

                <img src={vector} alt="" className="vector-five-img"
                    style={{
                        transform: PageProgress < progress1 ? 'translate(46vw, -15vh) rotate(80deg) scale(0.9)' :
                            PageProgress > progress1 && 'translate(46vw, -75vh) rotate(65deg) scale(0.9)',

                        filter: 'blur(10px)',
                        opacity: PageProgress > progress2 && '0.2'

                    }}
                />

                <div className="logo-container"
                    style={{
                        transform: PageProgress > progress1 && 'translateY(-100vh) scale(0.6)',

                    }}
                >
                    <img src={vector2} alt="" className="vector-six-img"
                        style={{
                            transform:
                                FirstCompAnime && 'rotate(0deg) scale(0.6)',
                        }}
                    />
                    <img src={AFTLogo} alt="" className="logo-img" />
                </div>

                <div className="first-comp-content-div"
                    style={{
                        transform: PageProgress > progress1 && 'translateY(-100vh) scale(0.6)'
                    }}
                >

                    <p className="top-text">
                        CONNECTING AT THE <b>INTERSECTION OF </b>
                    </p>


                    <p className="agriculture-text">AGRICULTURE</p>
                    <p className="agriculture-mirror-text" >AGRICULTURE</p>

                    <p className="food-and-technology-text"
                        style={{
                            transform: 'translateY(0vh)',
                            opacity: '1',
                            transition: PageProgress > progress1 && '1.8s cubic-bezier(.23, .16, 0, .99) 0s',

                        }}
                    ><b className='bold-text-1'>FOOD</b> AND<br /> <b className='bold-text-2'>TECHNOLOGY</b></p>


                </div>
            </div>
        </div >
    )
}
