import React, { useEffect, useState } from "react";

//styles
import "./ParaScroll.scss";

const ParaScroll = ({ PageProgress, Progress }) => {
  const [scrollId, setScrollId] = useState(1);
  const [scroll, setScroll] = useState(2);

  useEffect(() => {
    const handleScroll = (event) => {
      if (PageProgress > Progress[25] && PageProgress < Progress[26]) {
        setScrollId(1);
        setScroll(12);
      } else if (PageProgress > Progress[26] && PageProgress < Progress[27]) {
        setScrollId(2);
        setScroll(105);
      } else if (PageProgress > Progress[27] && PageProgress < Progress[28]) {
        setScrollId(3);
        setScroll(180);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [PageProgress]);

  const paraData = [
    {
      para: "Experience the synergy of in-depth, in-person conversations complemented by digital efficiency.",
    },
    {
      para: "Discover a unique blend of digital and physical event elements that enhance accessibility and interaction.",
    },
    {
      para: "Engage with industry leaders, potential partners, and innovative solutions, regardless of your location.",
    },
    {
      para: "Experience the synergy of in-depth, in-person conversations complemented by digital efficiency.",
    },
  ];

  return (
    <div
      className="scrolling-para"
      style={{
        transform: PageProgress > Progress[26] && "translate(0vw, 0vh)",
      }}
    >
      {paraData.map((item, i) => {
        return (
          <div
            className="para-slide"
            key={i}
            style={{
              opacity:
                i === scrollId
                  ? "1"
                  : i === scrollId - 1
                  ? "0.5"
                  : i === scrollId + 1
                  ? "0.5"
                  : "0.3",
              filter:
                i === scrollId
                  ? "blur(0px)"
                  : i === scrollId - 1
                  ? "blur(0px)"
                  : i === scrollId + 1
                  ? "blur(0px)"
                  : "blur(1px)",
              transform: `translateY(-${scroll}%)`,
            }}
          >
            <p
              style={{
                transform:
                  i === scrollId
                    ? "scale(1.2)"
                    : i === scrollId - 1
                    ? "scale(1)"
                    : i === scrollId + 1
                    ? "scale(1)"
                    : "scale(0.8)",
                fontWeight: i === scrollId ? "600" : "500",
                // color: i === scrollId ? '#fff' : '#E1FF01'
              }}
            >
              {item.para}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default ParaScroll;
