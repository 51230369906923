import React from "react";

import "./aiComp.scss";

// import  AiItConnectText from '../../../assets/ai-img/it-connect.png'

const PhoneMockupExtra =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/phonemockup-extra.png";
const ExtraFrame =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/extra-frame.png";
const AiGreenImg =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/ai-green-img.png";
const AiConnectText =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/connect-text.png";
// const AiImagineText =
//   "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/imaging-walking.png";
// const AiAFTCText =
//   "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/aftc-driven.png";
const AiItConnectText =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/it-connect.png";
const AiText =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/ai.png";
const LineBar =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/ai-below-line.png";
const FrameImg1 =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/ai-img1.png";
const FrameImg2 =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/ai-img2.png";
const FrameImg3 =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/ai-img3.png";
const AiLine1 =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/ai-line1.png";
const AiLine2 =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/ai-line2.png";
const AiLine3 =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/ai-line3.png";
const AiPhone =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/ai-phone.png";
const ScheduleText =
  "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/schedule-text.png";

const AiComp = ({ PageProgress, Progress }) => {
  const Progress0 = Progress[11];
  const Progress1 = Progress[12];
  const Progress2 = Progress[13];
  // const Progress3 = Progress[14];
  // const Progress4 = Progress[15];
  return (
    <div
      className="aicomp-main"
      style={{
        opacity:
          PageProgress > Progress0 && PageProgress < Progress2 ? "1" : "0",
        // transition: PageProgress > progress1 && '2.8s ease-out 3.5s',
        zIndex:
          PageProgress > Progress0 && PageProgress < Progress2 ? "1" : "0",
      }}
    >
      <div className="aicomp-div">
        <img
          src={AiText}
          alt=""
          className="ai-text"
          style={{
            opacity: PageProgress >= Progress0 && "0.1",
            transform:
              PageProgress >= Progress1 &&
              "translate(-300%,-50%) rotate(-20deg)",
            transition: PageProgress >= Progress2 && "1.8s ease-in-out",
            marginTop: PageProgress >= Progress1 && "-1vw",
          }}
        />

        <div
          className="ai-content-wrapper"
          style={{
            transform:
              PageProgress >= Progress1 && "translateX(-100%) rotate(-15deg)",
            marginTop: PageProgress >= Progress2 && "-1vw",
          }}
        >
          <img
            src={AiGreenImg}
            alt=""
            className="ai-green"
            style={{
              opacity: PageProgress >= Progress0 && "1",
              top: PageProgress >= Progress0 && "50%",
              transform:
                PageProgress >= Progress0 && PageProgress < Progress1
                  ? "translate(-50%, -50%) rotate(-45deg) scale(0.5)"
                  : PageProgress >= Progress1 && PageProgress < Progress2
                  ? "translate(-50%, -40%) rotate(-45deg) scale(0.5)"
                  : PageProgress >= Progress2 &&
                    "translate(-290%, -150%) scale(0.1)",
            }}
          />
          {/* <img
            src={AiGreenImg}
            alt=""
            className="ai-green2"
            style={{
              // left: PageProgress >= Progress2 && "50%",
              transform:
                PageProgress >= Progress2 &&
                "translate(-50%, -40%) scale(1.2) rotate(0)",
            }}
          /> */}
          <img
            src={AiConnectText}
            alt=""
            className="connect-text"
            style={{
              top:
                PageProgress >= Progress0 && PageProgress < Progress1
                  ? "50%"
                  : PageProgress >= Progress1 && "52%",
            }}
          />
          <h2
            className="ai-based"
            style={{
              opacity: PageProgress >= Progress0 && "1",
              transform:
                PageProgress >= Progress0 && "translatey(-27vw) scale(1.15)",
            }}
          >
            AI Based
          </h2>
          {/* --------------------------------------- */}
          <div
            className="ai-text-wrapper"
            style={{
              transform: PageProgress >= Progress0 && "translateY(0%) ",
            }}
          >
            <h2
              className="ai-htwo"
              style={{
                transform:
                  PageProgress >= Progress0 &&
                  "translate(2vw,-27vw) scale(1.15)",
              }}
            >
              Matchmaking
            </h2>
            <img
              src={LineBar}
              alt=""
              className="line-bar"
              style={{
                transform:
                  PageProgress >= Progress0 &&
                  "translate(2vw,-27vw) scale(1.15)",
              }}
            />
            {/* <img
              src={AiAFTCText}
              alt=""
              className="imagine-text"
              style={{
                opacity: PageProgress >= Progress1 && "0",
                transform:
                  PageProgress >= Progress1 && "translateY(-90%) scale(0.4)",
              }}
            /> */}
            {/* <img
              src={AiAFTCText}
              alt=""
              className="aftc-text"
              style={{
                opacity:
                  PageProgress >= Progress1 && PageProgress < Progress2
                    ? "1"
                    : PageProgress >= Progress2 && "0",
              }}
            /> */}
            <img
              src={AiItConnectText}
              alt=""
              className="itconnect-text"
              style={{
                opacity: PageProgress >= Progress0 && "1",
                transform: PageProgress >= Progress0 && "translateY(0%)",
              }}
            />
          </div>
        </div>

        <div className="ai-frame-wrapper">
          <div className="ai-phone-mockup">
            <img
              src={AiPhone}
              alt=""
              className="ai-phone"
              style={{
                opacity: PageProgress >= Progress1 && "1",
                // transition: PageProgress >= Progress4 && "2s ease-in-out",
                transform: PageProgress >= Progress2 && "translate(-50%,-200%)",
              }}
            />
            <img
              src={ScheduleText}
              alt=""
              className="schedule-text"
              style={{
                opacity:
                  PageProgress >= Progress1 && PageProgress < Progress2
                    ? "1"
                    : PageProgress >= Progress2 && "0",
              }}
            />

            <img
              src={LineBar}
              alt=""
              className="line-bar2"
              style={{
                opacity:
                  PageProgress >= Progress1 && PageProgress < Progress2
                    ? "1"
                    : PageProgress >= Progress2 && " 0",
              }}
            />
          </div>

          <div
            className="ai-line-bars"
            style={{
              opacity:
                PageProgress >= Progress0 && PageProgress < Progress1
                  ? "1"
                  : PageProgress >= Progress1 && "0",
            }}
          >
            <img src={AiLine1} alt="" className="ai-line1" />
            <img src={AiLine2} alt="" className="ai-line2" />
            <img src={AiLine3} alt="" className="ai-line3" />
          </div>

          <div
            className="frame-images"
            style={{
              transform:
                PageProgress >= Progress0 && "translateY(0%) skew(0,0)",
            }}
          >
            <div
              className="frame-img1"
              style={{
                transform:
                  PageProgress >= Progress1 && PageProgress < Progress2
                    ? "translate(17vw,14vw) rotate(-15deg)"
                    : PageProgress >= Progress2 &&
                      "translate(-35vw,14vw) rotate(-15deg)",
              }}
            >
              <p
                style={{
                  transform: PageProgress >= Progress0 && "translateX(0%)",
                }}
              >
                Top <span>Match</span>{" "}
              </p>
              <img src={FrameImg2} alt="" className="frame-img" />
            </div>
            <div
              className="frame-img2"
              style={{
                opacity: PageProgress >= Progress1 && "0",
              }}
            >
              <p
                style={{
                  transform: PageProgress >= Progress0 && "translateX(0%)",
                }}
              >
                Top <span>Match</span>{" "}
              </p>
              <img src={FrameImg3} alt="" className="frame-img" />
            </div>
            <div
              className="frame-img3"
              style={{
                opacity: PageProgress >= Progress1 && "0",
              }}
            >
              <p
                style={{
                  transform: PageProgress >= Progress0 && "translateX(0%)",
                }}
              >
                Top <span>Match</span>{" "}
              </p>
              <img src={FrameImg1} alt="" className="frame-img" />
            </div>
          </div>

          <img
            src={ExtraFrame}
            alt=""
            className="frame-img-extra"
            style={{
              opacity: PageProgress >= Progress1 && "1",
              transform:
                PageProgress >= Progress0 && PageProgress < Progress1
                  ? "rotate(3deg)"
                  : PageProgress >= Progress1 && PageProgress < Progress2
                  ? "translate(-18vw,-2.8vw) scale(1.4) rotate(20deg)  "
                  : PageProgress >= Progress2 && "translate(30vw,3vw)",
            }}
          />

          <div className="last-green-shape">
            <img
              src={AiGreenImg}
              alt=""
              className="left-green-shape"
              style={{
                opacity: PageProgress >= Progress2 && "0",
                transform:
                  PageProgress >= Progress1 &&
                  "translate(0%,0%) rotate(45deg) scale(1)",
              }}
            />
            <img
              src={AiGreenImg}
              alt=""
              className="right-green-shape"
              style={{
                opacity: PageProgress >= Progress2 && "0",
                transform:
                  PageProgress >= Progress1 &&
                  "translate(0%,0%) rotate(90deg) scale(1)",
              }}
            />
          </div>
          <div
            className="phone-mockup-extra"
            style={{
              transform: PageProgress >= Progress1 && "translate(0%,0vh) ",

              opacity: PageProgress >= Progress1 && "0",
            }}
          >
            <img
              src={PhoneMockupExtra}
              alt=""
              style={{
                transform: PageProgress >= Progress0 && "translate(0%,0vh)",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiComp;
