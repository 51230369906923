import React from 'react';

//styles
import './strategic-data-driven.scss';

//images
// import bgImg from '../../../imgs/home-page/people-are-standing-large-room-with-lot-lights.png';
// const vector = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/vector.png';
const frame1 = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/slides-frame01.png';
const frame2 = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/slides-frame02.png';
const frame3 = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/slides-frame03.png';
const frame4 = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/slides-frame04.png';
const frame5 = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/slides-frame05.png';
const vector2 = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame900.png';


export default function StrategicDataDriven({ PageProgress, Progress }) {

    const progress1 = Progress[3]
    const progress2 = Progress[4]
    const progress3 = Progress[5]


    return (
        <div className="strategic-data-driven-main-container"
            style={{
                opacity: PageProgress > progress1 && '1',
                transform: PageProgress > progress3 && 'translateY(-200vh)',
                transition: PageProgress > progress3 && '2.8s ease-out',
                zIndex: PageProgress > progress1 && '2'

            }}
        >

            <div className="strategic-data-driven-main">

                <div className="main-heading-div"
                    style={{
                        transform: PageProgress > progress2 && 'translateY(-100vh)'
                    }}
                >
                    {/* ------------------------------------------------------------------------------- */}
                    <div className="heading-div"
                        style={{
                            transform: PageProgress > progress1 && 'translate(0)',
                        }}

                    >
                        <div className="top-text">
                            {/* <span className="span1">
                                With
                                <b>our</b>
                            </span>&nbsp; */}
                            <span className="span2">With Our Strategic Data Driven</span>
                        </div>

                        <div className="bottom-text">
                            <span className="span1">
                                Structured
                            </span>&nbsp;

                            <span className="span2">net
                                <span className="sub-span">working.</span>
                            </span>

                        </div>

                    </div>
                    {/* ------------------------------------------------------------------------------- */}

                    {/* ------------------------------------------------------------------------------- */}
                    <div className="overcome-heading"
                        style={{
                            opacity: PageProgress > progress1 && '1',
                        }}
                    >
                        <div className="top-text">
                            <span className='span1'>Overcome</span>&nbsp;
                            <span className='span2'>The</span>&nbsp;
                            <span className='span3'>Limitations</span>
                        </div>
                        <div className="bottom-text"
                            style={{
                                transform: 'translateY(0vh)'
                            }}
                        >
                            <span className="span1">Of</span>&nbsp;&nbsp;
                            <span className='span2'>Traditional</span>&nbsp;
                            <span className='span3'>net<span className='sub-span'>working</span></span>
                        </div>

                    </div>
                    {/* ------------------------------------------------------------------------------- */}
                </div>



                <div className="next-content-div"
                    style={{
                        marginTop: PageProgress > progress2 && '56vh',
                    }}
                >
                    <div className="content-text"
                        style={{
                            transform: PageProgress > progress2 && 'translateY(0) scale(1)'
                        }}
                    >
                        Embrace the future where innovation <br />
                        thrives across Agri Food & Tech <br />
                        Industries, where <span>every conversation</span><br />
                        could lead to your <span>next opportunity.</span>

                    </div>

                    <img src={vector2} alt="" className="vector2-img" />

                </div>


                <div className="carousel-div"
                    style={{
                        transform: PageProgress > progress1 && PageProgress < progress2 ? 'translate(-0vh)' :
                            PageProgress > progress2 && 'translate(0vh, -45vh)',
                        opacity: PageProgress > progress1 && '1'
                    }}
                >
                    <div className="slides-div"
                        style={{
                            transform: PageProgress > progress2 && 'translate(-35%)'
                        }}
                    >

                        <img src={frame1} alt="" className="slide-img" />
                        <img src={frame2} alt="" className="slide-img" />
                        <img src={frame3} alt="" className="slide-img" />
                        <img src={frame4} alt="" className="slide-img" />
                        <img src={frame5} alt="" className="slide-img" />


                    </div>
                </div>


            </div>
        </div>
    )
}
