import React, { useState, useEffect, useMemo } from "react";

// import { Canvas } from "@react-three/fiber";
//styles
import "./home-page.scss";

//libraries
import Stickyroll from "@stickyroll/react/stickyroll";

// components
import FirstComp from "./First-Comp/first-comp";
import ForgingConnection from "./Forging-Connections/forging-connection";
import OvercomeLimitationsComp from "./Overcome-Limitations-Comp/overcome-limitations-comp";
// import PreventScrolling from '../components/Prevent-Scrolling/prevent-scrolling';
import StrategicDataDriven from "./Strategic-Data-Driven-Comp/strategic-data-driven";
import BantProcessComp from "./Bant-Process-Comp/bant-process-comp";
import DynamicCircleComp from "../components/Dynamic-Circle-Comp/dynamic-circle-comp";
import CuratedMeetingComp from "./Curated-Meeting-Comp/curated-meeting-comp";
import AnotherLayer from "./Sahil_Code/AnotherLayer/AnotherLayer";
import AiComp from "./AiComp/AiComp";
// import EqualTo from "./Equal-To-Comp/EqualTo";
import CalendarOne from "./CalendarOne/CalendarOne";
import Benefit from "./Benifit/Benefit";
import OptimizeTime from "../components/OptimizeTime/OptimizeTime";
import ThankYou from "./Thank-You/ThankYou";
import PreventScrolling from "../components/Prevent-Scrolling/prevent-scrolling";
import ScrollDownComp from "../components/Scroll-Down-Comp/scroll-down-comp";
// import LogoModelComp from "../components/Model-Comp/Logo-Model-Comp/logo-model";
import PopUpForm from "../components/Form/PopUpForm";
import Sponsor from "./Sponsor/Sponsor";
import Package from "../components/PackagePage/Package";

// images
const mainBgImg =
  "https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame866.png";
const navVector =
  "https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame872.png";
const logo =
  "https://rentblob.blob.core.windows.net/agri-food-tech/home-page/agri-logo.svg";

const mainLogo =
  "https://rentblob.blob.core.windows.net/agri-food-tech/home-page/agri-food-tech-logo-3.png";

export default function HomePage({ HandlePathname, Query }) {
  const [pageProgress, setPageProgress] = useState(0);
  const [firstCompAnime, setFirstCompAnime] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line
  const [isTouchReleased, setIsTouchReleased] = useState(false);
  const [navLoader, setNavLoader] = useState(false);
  const [formState, setFormState] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 750);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    HandlePathname();
    // eslint-disable-next-line
  }, []);

  const handleNavLoader = () => {
    setNavLoader(true);
  };

  useEffect(() => {
    navLoader &&
      setTimeout(() => {
        setNavLoader(false);
      }, 2500);
  }, [navLoader]);

  // const progress = [
  //   0.0253, 0.0506, 0.076, 0.101, 0.126, 0.152, 0.177, 0.2024, 0.227, 0.253,
  //   0.278, 0.303, 0.329, 0.354, 0.379, 0.405, 0.430, 0.455, 0.481, 0.506,
  //   0.5313, 0.556, 0.582, 0.607, 0.6325, 0.658, 0.683, 0.708, 0.734, 0.76,
  //   0.784, 0.809, 0.835, 0.860, 0.885, 0.911, 0.936, 0.961, 0.986
  // ];

  // const progress = [
  //   0.02, 0.04, 0.06, 0.08, 0.1, 0.12, 0.14, 0.16, 0.18, 0.2, 0.22, 0.24, 0.26,
  //   0.28, 0.3, 0.32, 0.34, 0.36, 0.38, 0.4, 0.42, 0.44, 0.46, 0.48, 0.5, 0.52,
  //   0.54, 0.56, 0.58, 0.6, 0.62, 0.64, 0.66, 0.68, 0.7, 0.72, 0.74, 0.76, 0.78,
  //   0.8, 0.82, 0.84, 0.86, 0.88, 0.9, 0.925, 0.945, 0.965, 0.985
  // ];
  const progress = [
    0.025, 0.05, 0.075, 0.1, 0.125, 0.15, 0.175, 0.2, 0.225, 0.25, 0.275, 0.3,
    0.325, 0.35, 0.375, 0.4, 0.425, 0.45, 0.475, 0.5, 0.525, 0.55, 0.574, 0.597,
    0.62, 0.643, 0.667, 0.69, 0.714, 0.738, 0.761, 0.786, 0.811, 0.835, 0.86,
    0.884, 0.908,0.931, 0.954, 0.974, 0.989,
  ];

  const progress1 = progress[27];
  const progress2 = progress[35];
  const progress3 = progress[37];

  useEffect(() => {
    !firstCompAnime &&
      setTimeout(() => {
        setFirstCompAnime(true);
      }, 800);
    // eslint-disable-next-line
  }, []);
  const [scrollDownGif, setScrollDownGIF] = useState(true);

  const handleMouseOver = () => {
    setScrollDownGIF(true);
  };

  useMemo(() => {
    scrollDownGif && setScrollDownGIF(false);
    // eslint-disable-next-line
  }, [pageProgress]);

  // ------------------------------------------------------------

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="bg-img-div">
          <img src={mainBgImg} alt="" className="bg-img" />
        </div>
        <div className="loader-bg">
          <div className="loader-div">
            <img src={logo} alt="Logo" className="logo" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="home-page-main-container" onMouseOver={handleMouseOver}>
      <div
        className="loader-container nav-bar-loader"
        style={{
          display: !navLoader && "none",
        }}
      >
        <div className="bg-img-div">
          <img src={mainBgImg} alt="" className="bg-img" />
        </div>
        <div className="loader-bg">
          <div className="loader-div">
            <img src={logo} alt="Logo" className="logo" />
          </div>
        </div>
      </div>

      <div
        className="join-us-floating-div"
        onClick={() => {
          setFormState(true);
        }}
      >
        JOIN <br /> US
      </div>
      {/* -----------------------------------SCROLL DOWN ICON--------------------------------------------- */}
      {scrollDownGif && pageProgress < 0.99 && <ScrollDownComp />}
      {/* ------------------------------------------------------------------------------------------------ */}

      {/* -----------------------------------Touch Preventing Div--------------------------------------------- */}

      {isTouchReleased && (
        <div className="hidden-touch-preventiong-div">
          <PreventScrolling />
        </div>
      )}

      {/* ----------------------------------------FORM POPUP---------------------------- */}

      {formState && <PopUpForm SetFormState={setFormState} />}

      {/* --------------------------------------Navigation---------------------------------------- */}

      <div className="canvas-main-container">
        <img
          src={mainLogo}
          alt=""
          className="main-logo-img"
          onClick={() => {
            window.scrollTo({ top: (progress[0]-0.02) * window.innerHeight * 50.5 });
            handleNavLoader();
          }}
        />
        <ul className="nav-list-top">
          <div
            className="navigate"
            onClick={() => {
              window.scrollTo({ top: progress[5] * window.innerHeight * 50.5 });
              handleNavLoader();
            }}
          >
            Our Flow
          </div>
          <div
            className="navigate"
            onClick={() => {
              window.scrollTo({
                top: progress[13] * window.innerHeight * 50.5,
              });
              handleNavLoader();
            }}
          >
            Curated Meetings
          </div>
          <div
            className="navigate"
            onClick={() => {
              window.scrollTo({
                top: progress[15] * window.innerHeight * 50.5,
              });
              handleNavLoader();
            }}
          >
            Events
          </div>
          <div
            className="navigate"
            onClick={() => {
              window.scrollTo({
                top: progress[24] * window.innerHeight * 50.5,
              });
              handleNavLoader();
            }}
          >
            join us
          </div>
          <div
            className="navigate"
            onClick={() => {
              window.scrollTo({
                top: progress[27] * window.innerHeight * 50.5,
              });
              handleNavLoader();
            }}
          >
            Sponsor
          </div>
          <div
            className="navigate"
            onClick={() => {
              window.scrollTo({
                top: progress[40] * window.innerHeight * 50.5,
              });
              handleNavLoader();
            }}
          >
            contact
          </div>
        </ul>
      </div>

      {/* ------------------------------------------------------------------------------------------- */}

      <Stickyroll
        pages={1}
        factor={!Query ? 50 : 80}
        onProgress={(p) => setPageProgress(p)}
      >
        <div className="home-page-main">
          {/* <p style={{
        color:"red",
        position: "absolute",
        top: "0%",
        zIndex:"100"
      }}>{pageProgress}</p> */}
          <div className="main-bg-img-div">
            <img
              src={mainBgImg}
              alt=""
              className="main-bg-img"
              style={{
                opacity:
                  pageProgress > progress[1] && pageProgress < progress[3]
                    ? "0"
                    : pageProgress > progress[3] && "0",
                transform:
                  pageProgress > progress[0] && pageProgress < progress[1]
                    ? "rotate(-90deg)"
                    : pageProgress > progress[1] && "rotate(-0deg)",
              }}
            />
          </div>
          <DynamicCircleComp
            PageProgress={pageProgress}
            Progress={progress}
            SetFormState={setFormState}
          />
          <FirstComp
            PageProgress={pageProgress}
            Progress={progress}
            FirstCompAnime={firstCompAnime}
            FormState={formState}
            SetFormState={setFormState}
          />
          <ForgingConnection PageProgress={pageProgress} Progress={progress} />
          <OvercomeLimitationsComp
            PageProgress={pageProgress}
            Progress={progress}
          />
          <StrategicDataDriven
            PageProgress={pageProgress}
            Progress={progress}
          />
          <BantProcessComp PageProgress={pageProgress} Progress={progress} />
          <AnotherLayer PageProgress={pageProgress} Progress={progress} />
          <AiComp PageProgress={pageProgress} Progress={progress} />
          {/* {pageProgress > progress[1] && pageProgress < progress[21] && */}
          <CuratedMeetingComp PageProgress={pageProgress} Progress={progress} />
          {/* <EqualTo PageProgress={pageProgress} Progress={progress} /> */}
          <CalendarOne PageProgress={pageProgress} Progress={progress} />
          <Benefit PageProgress={pageProgress} Progress={progress} />
          {pageProgress > progress1 && pageProgress < progress3 &&(
            <Sponsor PageProgress={pageProgress} Progress={progress} />
          )}
          {pageProgress >= progress2 && (
            <OptimizeTime PageProgress={pageProgress} Progress={progress} />
          )}
          {pageProgress >= progress[38] && (
            <Package PageProgress={pageProgress} Progress={progress} />
          )}
          <ThankYou
            PageProgress={pageProgress}
            Progress={progress}
            SetFormState={setFormState}
          />

          <img
            src={navVector}
            alt=""
            className="nav-vector-img"
            style={{
              opacity: firstCompAnime && pageProgress > progress[0] && "1",
              transform:
                pageProgress > progress[1] && pageProgress < progress[2]
                  ? "translateY(5vh)"
                  : pageProgress > progress[2] && pageProgress < progress[4]
                  ? "translateY(2vh)"
                  : pageProgress > progress[4] &&
                    "translateY(20vh) scaleY(0.6)",
              transition:
                pageProgress > progress[2] &&
                pageProgress < progress[3] &&
                "1.8s ease-out 1.5s",
            }}
          />
          <div
            className="footer-section-home-page"
            style={{
              transform: pageProgress < progress[40] && "translateY(20vh)",
            }}
          >
            <div className="icon-div">
              <img
                src="https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/facebook-icon.png"
                alt=""
              />
              <a
                href="https://www.facebook.com/profile.php?id=61554009024374"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </div>
            <div className="icon-div">
              <img
                src="https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/linkdin.png"
                alt=""
              />
              <a
                href="https://www.linkedin.com/company/101276098/admin/feed/posts/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </div>
            <div className="icon-div">
              <img
                src="https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/instagram.png"
                alt=""
              />
              <a
                href="https://www.instagram.com/agri_food_tech_connect/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </Stickyroll>
    </div>
  );
}
