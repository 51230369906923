import React from 'react';

//styles
import './curated-meeting-comp.scss'

// Component
import VideoCard from './video-card-one/video-card';


//videos
// const Video1 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video1.mp4';
// const Video2 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video2.mp4';
// const Video3 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video3.mp4';
// const Video4 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video4.mp4';
// const Video5 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video5.mp4';
// const Video6 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video6.mp4';
// const Video7 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video7.mp4';
const Video8 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video8.mp4';
// const Video9 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video9.mp4';
const Video10 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video10.mp4';
const Video12 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video12.mp4';
const Video13 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video13.mp4';
const Video14 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video14.mp4';
const Video15 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video15.mp4';
const Video16 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video16.mp4';
// import Video17 from '../../../imgs/videos/Video17.mp4';
const Video18 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video18.mp4';
const Video19 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video19.mp4';
const Video20 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Video20.mp4';
// import Video21 from '../../../imgs/videos/Video21.mp4';
const Video22 = 'https://rentblob.blob.core.windows.net/agri-food-tech/videos/Web-Cam-Shot.mp4';

//images
const vectorImg = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame895.png';
const AIMatching = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame904.png';
const GroupImg = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Group1160.png';
const textImg = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame902.png';

export default function CuratedMeetingComp({ PageProgress, Progress }) {
    const Progress0 = Progress[13];
    const Progress1 = Progress[14];
    const Progress2 = Progress[15];

    return (
        <div className="curated-meeting-comp-main-container"

            style={{
                transform: PageProgress > Progress0 && PageProgress < Progress2 ? 'translateY(0) scale(1) rotate(0deg)' :
                    PageProgress > Progress2 && 'translateY(-100vh)',
                opacity: PageProgress > Progress0 && '1'
            }}
        >
            <div className="curated-meeting-comp-main">

                <div className="content-main-div"
                    style={{
                        transform: PageProgress > Progress0 && PageProgress < Progress1 ? 'translateY(0)' :
                            PageProgress > Progress1 && `scale(0.65) translate(-32vw, -10vh)`

                    }}
                >

                    <div className="circle-content-container">
                        <div className="before-div" />
                        <div className="bg-circle" />

                    </div>
                    {/* --------------------------------VIDEO-CARD-ONE----------------------------------------------- */}
                    <div className="video-card-one">
                        <img src={GroupImg} alt="" className="video-card-one-img another-img"
                            style={{
                                opacity: PageProgress < Progress1 && '1'
                            }}
                        />
                        <img src={AIMatching} alt="" className="video-card-one-img"
                            style={{
                                opacity: PageProgress > Progress1 && '1'
                            }}
                        />
                    </div>
                    {/* --------------------------------VIDEO-CARD-TWO----------------------------------------------- */}
                    <div className="video-card-two">

                        <VideoCard
                            Video1={Video16}
                            Video2={Video12}
                            Video3={Video13}
                            Video4={Video14}
                            Video5={Video15}
                            Video6={Video19}
                            Video7={Video10}
                            Video8={Video18}
                        />
                    </div>
                    {/* --------------------------------VIDEO-CARD-THREE----------------------------------------------- */}
                    <div className="video-card-three">
                        <div className="before-div" />

                        <div className="inner-div">
                            <div className="inner-video-frame">
                                <video
                                    className='video-palyer'
                                    autoPlay={true}
                                    muted
                                    loop={true}
                                    playsInline
                                // preload={true}
                                >
                                    <source src={Video22} type="video/mp4" />
                                </video>
                            </div>
                            <div className="text-div">
                                Beneficiaries
                            </div>
                            <div className="dummy-data-div">
                                <hr className="hr-line" />
                                <hr className="hr-line" />
                                <hr className="hr-line" />
                                <hr className="hr-line" />
                                <hr className="hr-line" />

                            </div>

                        </div>
                    </div>
                    {/* --------------------------------VIDEO-CARD-FOUR----------------------------------------------- */}

                    <div className="video-card-four">
                        <div className="before-div" />

                        <div className="inner-div">

                            <div className="video-frame">
                                <div className="inner-video-frame-one">
                                    <video
                                        className='video-palyer'
                                        autoPlay={true}
                                        muted
                                        loop={true}
                                        playsInline

                                    // preload={true}
                                    >
                                        <source src={Video8} type="video/mp4" />
                                    </video>
                                </div>

                                <div className="inner-video-frame-two">
                                    <video
                                        className='video-palyer'
                                        autoPlay={true}
                                        muted
                                        loop={true}
                                        playsInline

                                    // preload={true}
                                    >
                                        <source src={Video20} type="video/mp4" />
                                    </video>
                                </div>
                            </div>

                            <div className="text-div">
                                Service Providers
                            </div>

                        </div>
                    </div>
                    {/* ---------------------------------------------------------------------------------------------- */}
                    <div className="vector-div">
                        <img src={vectorImg} alt="" className="vector-img"
                            style={{
                                transform: PageProgress > Progress1 && 'rotate(0)'
                            }}
                        />
                        <div className="hr-line-one" />
                        <div className="hr-line-two" />

                        <p className='text-para'
                            style={{
                                opacity: PageProgress > Progress0 && PageProgress < Progress1 && '1'
                            }}
                        >
                            Curated <br />
                            1-2-1 <br />
                            Meetings
                        </p>
                        <p className="para-second"
                            style={{
                                opacity: PageProgress > Progress0 && PageProgress < Progress1 && '1'
                            }}
                        >
                            at the <span>event</span>
                        </p>

                        <p className='para-third'
                            style={{
                                opacity: PageProgress > Progress1 && '1'
                            }}
                        >
                            Interest <br />
                            Intent <br />
                            AI Matches
                        </p>


                    </div>


                </div>

                <div className="text-content-container"
                    style={{
                        transform: PageProgress > Progress1 && 'translate(0,10vh)',
                        opacity: PageProgress > Progress1 && '1'
                    }}
                >
                    {/* <p className="para-one">
                        <b>AFTC</b> is where visionaries converge to <span>explore, <br />
                            exchange, and evolve</span>. Our unique series of <br />
                        events starting from <span>INDIA</span> are designed to <br />
                        initiate discussions that matter,
                    </p>
                    <p className="para-two">
                        providing a platform for <span>technology</span> to meet <br />
                        the practical needs of <span>food and agricultural</span> <br />
                        businesses.
                    </p> */}

                    <img src={textImg} alt="" className="text-img" />


                </div>
            </div>
        </div>
    )
}
