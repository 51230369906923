import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";

const LocationIcon = "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/location-icon.png";
const TermsIcon = "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/terms.png";
const PrivacyIcon = "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/privacy.png";

const LinkedinIcon = "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/linkdin.png"
const InstagramIcon = "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/instagram.png"
const FacebookIcon = "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/facebook-icon.png"
const JoinusBtn = 'https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/joinus-btn.png';
const RefundImg = "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/refund-policy.png"


const Footer = (props) => {

  return (
    <div className="footer-section">
      <hr />
      <div className="footer-wrapper">
        <div className="left-footer">
          <img src={LocationIcon} alt="" className="location-icon" />

          <p className="footer-address">
            T-Hub, Plot no 1/c, sy no 83/1, Raidurgram Panmaktha <br />{" "}
            Hyderabad Knowledge City, Serilingamaplly, Hyderabad, <br />
            Telangana 500081<br />
            IVR number - +919355402246
          </p>
        </div>
        <div className="right-footer">
          <div className="left-ul">
            <li><img src={FacebookIcon} alt="" />
              <a href={props.FacebookHref} target="_blank" rel="noreferrer">
                Facebook</a>
            </li>
            <li><img src={LinkedinIcon} alt="" />
              <a
                href={props.LinkedinHref} target="_blank" rel="noreferrer">
                LinkedIn</a>
            </li>
            <li>
              <img src={InstagramIcon} alt="" />
              <a href={props.instagramHref} target="_blank" rel="noreferrer">
                Instagram</a>
            </li>
          </div>
          <div className="right-ul">
            <li>
              <img src={PrivacyIcon} alt="" />
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <img src={RefundImg} alt="" />
              <Link to="/refund-policy">Refund Policy</Link>
            </li>
            <li>
              <img src={TermsIcon} alt="" />
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </li>

          </div>
        </div>

        <div className="join-us-btn" onClick={() => { props.SetFormState(true) }}>
          <img src={JoinusBtn} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
