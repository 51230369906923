import React from "react";
import { useNavigate } from "react-router-dom";
import "./navbar.scss";

const MainLogo2 =
  "https://rentblob.blob.core.windows.net/agri-food-tech/sponsor/main-logo.png";

const Navbar = ({popUp, setPopUp}) => {
    const navigate = useNavigate();
  return (
      <div className="navbar-main">
        <div className="left-nav">
          <img src={MainLogo2} alt="" onClick={() => navigate("/")} />
        </div>
        <div className="right-nav">
          <ul>
            <a
              href="https://www.canva.com/design/DAFZF1HKzA8/ob-vBjfitb1A9ghsA4SsVw/edit?utm_content=DAFZF1HKzA8&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
              target="_blank"
              rel="noreferrer"
            >
              About Us
            </a>
            <div>Partnerships</div>
       
            <div onClick={() => setPopUp(!popUp)} >Contact Us</div>
            <div>Sponsor</div>
          </ul>
        </div>
      </div>
  );
};

export default Navbar;
