import { useState, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Points, PointMaterial } from "@react-three/drei";
import * as random from "maath/random/dist/maath-random.esm";


//styles
import "./ThankYou.scss";

//assets
const logo =
  "https://rentblob.blob.core.windows.net/agri-food-tech/home-page/agri-logo.svg";
const bookNowBtn =
  "https://rentblob.blob.core.windows.net/agri-food-tech/home-page/book-now-btn.png";

export default function ThankYou({ PageProgress, Progress, SetFormState }) {

  return (
    <div
      className="thank-you-main-container"
      style={{
        opacity: PageProgress >= Progress[40] ? "1" : "0",
        zIndex: PageProgress >= Progress[40] ? "1" : "0",


      }}
    >
      <div className="thank-you-main">
        <Canvas camera={{ position: [0, 0, 1] }}>
          <Stars />
        </Canvas>
        <Overlay SetFormState={SetFormState} />
      </div>
    </div>
  );
}

function Stars(props) {
  const ref = useRef();
  const [sphere] = useState(() =>
    random.inSphere(new Float32Array(5000), { radius: 1.5 })
  );
  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 10;
    ref.current.rotation.y -= delta / 15;
  });

  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      <Points
        ref={ref}
        positions={sphere}
        stride={3}
        frustumCulled={false}
        {...props}
      >
        <PointMaterial
          transparent
          color="#013220"
          // color="#fff"
          size={0.005}
          sizeAttenuation={true}
          depthWrite={false}
        />
      </Points>
    </group>
  );
}




function Overlay({ SetFormState }) {

  return (
    <div
      style={{
        // position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "35%",
          left: "50%",
          transform: "translate3d(-50%,-50%,0)",
          zIndex: "1",
        }}
      >
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div
        style={{
          position: "absolute",
          top: "55%",
          left: "50%",
          transform: "translate3d(-50%,-50%,0)",
        }}
      >
        <h1>
          <span className="text-light">your gateway to</span> Agri, Food & Tech{" "}
          <br />
          <span className="text-white"> innovation</span> starts with AFTC
        </h1>
      </div>
      <button
        className="book-now-btn"
        onClick={() => { SetFormState(true) }}
        style={{
          position: "absolute",
          top: "66%",
          left: "50%",
          transform: "translate3d(-50%, 50%,0)",
          backgroundImage: `url(${bookNowBtn})`,
        }}
      ></button>
    </div>
  );
}
