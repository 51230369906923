import React, { useState } from "react";
import "./Package.scss";

const ButtonBox =
  "https://rentblob.blob.core.windows.net/agri-food-tech/PackageImgs/ButtonBox.png";
const Lappy =
  "https://rentblob.blob.core.windows.net/agri-food-tech/PackageImgs/Lappy.png";
const SponsorshipPackagesLogo =
  "https://rentblob.blob.core.windows.net/agri-food-tech/PackageImgs/SponsorshipPackagesLogo.png";
const SponsorshipPackagesText =
  "https://rentblob.blob.core.windows.net/agri-food-tech/PackageImgs/SponsorshipPackagesTxt.png";
const SponsorBgImg =
  "https://rentblob.blob.core.windows.net/agri-food-tech/PackageImgs/SponsorBgImg.png";
const preEventImg =
  "https://rentblob.blob.core.windows.net/agri-food-tech/PackageImgs/preEventImg.png";
const duringEventImg =
  "https://rentblob.blob.core.windows.net/agri-food-tech/PackageImgs/duringEventImg.png";
const postEventImg =
  "https://rentblob.blob.core.windows.net/agri-food-tech/PackageImgs/postEventImg.png";
const speakingImg =
  "https://rentblob.blob.core.windows.net/agri-food-tech/PackageImgs/speakingImg.png";
const networkingImg =
  "https://rentblob.blob.core.windows.net/agri-food-tech/PackageImgs/networkingImg.png";
const ViewPackagesText =
  "https://rentblob.blob.core.windows.net/agri-food-tech/PackageImgs/ViewPackagesText.png";

const NewSponsorPage = ({ PageProgress, Progress }) => {
  const [clickBtn, setClickBtn] = useState(false);
  const progress17 = Progress[39];
  const progress18 = Progress[40];
  
  return (
    <div
      className="new-main-container"
      style={{
        opacity:
          PageProgress >= progress17 && PageProgress < progress18 ? 1 : 0,
        zIndex: PageProgress >= progress17 && PageProgress < progress18 ? 9 : 0,
      }}
    >
      <div className="fixed-container1">
        <img src={SponsorBgImg} alt="background" className="sponsor-bg" />
        <img src={Lappy} alt="lappy" className="lappy-sponsor" />
        <img
          src={SponsorshipPackagesLogo}
          alt="logo"
          className="logo-sponsor"
        />
        <img
          src={SponsorshipPackagesText}
          alt="text"
          className="sponsor-text"
        />
        <div
          className="button-box-container"
          onClick={() => {
            setClickBtn(true);
          }}
        >
          <img src={ButtonBox} alt="button box" className="button-box" />
          <img
            src={ViewPackagesText}
            alt="view text"
            className="view-packages-text"
          />
        </div>
      </div>

      <div className="scroll-main" style={{ top: clickBtn && "10%" }}>
        <div className="scroll-container2" id="test">
          <p
            className="cross"
            onClick={() => {
              setClickBtn(false);
              document.getElementById("test").scrollTo(0, 0);
            }}
            style={{ opacity: clickBtn && 1 }}
          >
            &#x292C;
          </p>

          <img src={preEventImg} alt="preEvent" className="pre-event-img" />
          <img
            src={duringEventImg}
            alt="duringEvent"
            className="during-event-img"
          />
          <img src={postEventImg} alt="postEvent" className="post-event-img" />
          <img src={speakingImg} alt="speaking Img" className="speaking-Img" />
          <img
            src={networkingImg}
            alt="networking Img"
            className="networking-Img"
          />
        </div>
      </div>
    </div>
  );
};

export default NewSponsorPage;
