import React from 'react';

// styles
import './bottom-navbar.scss';

//img
const vector = 'https://rentblob.blob.core.windows.net/agri-food-tech/home-page/Frame896.png';

export default function BottomNavbar({ PageProgress, Progress }) {
    const progress1 = Progress[5]
    const progress1_1 = Progress[7]
    const progress2 = Progress[8]
    const progress2_1 = Progress[10]
    const progress3 = Progress[11]
    // const progress4 = Progress[12]
    const progress5 = Progress[13]


    // console.log(PageProgress - progress1)

    return (
        <div className="bottom-navbar-main-container"
            style={{
                transform: PageProgress > progress5 && 'translateY(40vh)'
            }}
        >
            <div className="bottom-navbar-main">
                <div className="span-img"
                    style={{
                        backgroundImage: `url(${vector})`,
                        transform: PageProgress > progress1_1 && PageProgress < progress2 ? `translateX(3.2vw) rotate(180deg)` :
                            PageProgress > progress2 && PageProgress < progress2_1 ? `translateX(18.5vw) rotate(360deg)` :
                                PageProgress > progress2_1 && PageProgress < progress3 ? `translateX(32.5vw) rotate(540deg)` :
                                    PageProgress > progress3 && `translateX(46.4vw) rotate(620deg)`,

                    }}
                />
                <div className="hr-line"
                    style={{
                        clip:  PageProgress > progress1_1 && PageProgress < progress2 ?`rect(0px, ${3.5}vw, 1px, 0px)` :
                            PageProgress > progress2 && PageProgress < progress2_1 ? `rect(0px, ${18.7}vw, 1px, 0px)` :
                                PageProgress > progress2_1 && PageProgress < progress3 ? `rect(0px, ${32.85}vw, 1px, 0px)` :
                                    PageProgress > progress3 && `rect(0px, ${46.7}vw, 1px, 0px)`,


                    }}
                />

                <div className="name-text"
                    style={{
                        color: PageProgress < progress1_1 ? '#fff' :
                            PageProgress > progress1 && PageProgress < progress2 && '#0DF40F'
                    }}
                >
                    Qualification
                </div>
                <div className="name-text"
                    style={{
                        color: PageProgress > progress2 && PageProgress < progress2_1 && '#0DF40F'
                    }}
                >
                    Profile Setup
                </div>

                <div className="name-text"
                    style={{
                        color: PageProgress > progress2_1 && PageProgress < progress3 && '#0DF40F'
                    }}
                >
                    Interest
                </div>

                {/* <div className="name-text"
                    style={{
                        color: PageProgress > progress4 && PageProgress < progress5 && '#0DF40F'
                    }}
                >
                    Intent
                </div> */}

                <div className="name-text"
                    style={{
                        color: PageProgress > progress3 && '#0DF40F'
                    }}
                >
                    Top Matches
                </div>



            </div>

        </div>
    )
}
