import React, { useEffect, useState } from "react";
import "./benefit.scss";

const AudienceRight =
  "https://rentblob.blob.core.windows.net/agri-food-tech/sponsor/s2-right-sec.png";
const AudienceLeft =
  "https://rentblob.blob.core.windows.net/agri-food-tech/sponsor/s2-left-sec.png";
const CenterCircle =
  "https://rentblob.blob.core.windows.net/agri-food-tech/sponsor/circle-div.png";

const MockUpS1 =
  "https://rentblob.blob.core.windows.net/agri-food-tech/sponsor/bg-overlay.png";
// import S2Bg from "../../assets/agri-tech/s2-benefits.png";
const GreenOverlay =
  "https://rentblob.blob.core.windows.net/agri-food-tech/sponsor/s2-overlay.png";

const Benefit = ({ PageProgress, Progress }) => {
  const [benefitBg, setBenefitBg] = useState(false);
  const [leftAudience, setLeftAudience] = useState(false);
  const [rightAudience, setRightAudience] = useState(false);

  const progress0 = Progress[24];
  const progress1 = Progress[25];
  const progress2 = Progress[26];
  const progress3 = Progress[27];

  useEffect(() => {
    setTimeout(() => {
      if (PageProgress >= progress1 && PageProgress < progress2) {
        setBenefitBg(true);
      } else {
        setBenefitBg(false);
      }
    }, 2000);

    // eslint-disable-next-line
  }, [Progress]);

  return (
    <div
      className="benefits-main"
      style={{
        opacity:
          PageProgress >= progress0 && PageProgress < progress3
            ? "1"
            : PageProgress >= progress3 && "0",
        visibility: PageProgress >= progress0 && "visible",
        zIndex:
          PageProgress >= progress0 && PageProgress < progress3 ? "2" : "0",
      }}
    >
      <div className="benefits-wrapper">
        <div className="benefits-s1">
          <h2
            style={{
              transform:
                PageProgress >= progress0 && PageProgress < progress2
                  ? "translate(-50%, -50%)"
                  : PageProgress >= progress2 && "translate(-50%, -400%)",
              animation:
                PageProgress >= progress1 && "BgPositionAnim  4s ease-in-out",
              color: benefitBg && "black",
              opacity: PageProgress >= progress2 && "0",
              backgroundSize: PageProgress >= progress0 && "1000%",
            }}
          >
            Join Us
          </h2>
          <img src={MockUpS1} alt="" className="s1-mockup" />
          <div
            className="s1-overlay1"
            style={{
              transform:
                PageProgress >= progress1 &&
                "translate(100%,100%) rotate(25deg)",
            }}
          />
          <div
            className="s1-overlay2"
            style={{
              width: PageProgress >= progress2 && "150%",
              transform:
                PageProgress >= progress2 &&
                "translate(-10%,-10%) rotate(25deg)",
            }}
          />
        </div>

        {/* ------------- slide two -------------- */}

        <div className="benefits-s2">
          <div
            className="s2-heading-wrapper"
            style={{
              transform: PageProgress >= progress2 && "translateY(-16vw)",
              opacity: PageProgress >= progress2 && "1",
              backgroundSize: PageProgress >= progress2 && "1000%",
            }}
          >
            <h2
              style={{
                backgroundSize: PageProgress >= progress2 && "1000%",
                transform: rightAudience
                  ? "translate(21vw,5%)"
                  : leftAudience && "translate(-35vw,5%)",
              }}
              className="h-two"
            >
              Join Us
            </h2>
          </div>

          <div className="s2-image-wrapper">
            <img
              onMouseEnter={() => {
                setLeftAudience(false);
                setLeftAudience(true);
              }}
              onMouseLeave={() => {
                setLeftAudience(false);
              }}
              src={AudienceRight}
              alt=""
              className="s2-audeience-left"
              style={{
                transform:
                  PageProgress >= progress2 && "translate(0%,0%) scaleY(1)",
                filter: rightAudience && "blur(0.4vw)",
                opacity: PageProgress >= progress2 && "1",
              }}
            />
            <img
              src={AudienceLeft}
              alt=""
              className="s2-audeience-right"
              style={{
                transform:
                  PageProgress >= progress2 && "translate(0%,0%) scaleY(1)",
                filter: leftAudience && "blur(0.4vw)",
                opacity: PageProgress >= progress2 && "1",
              }}
              onMouseEnter={() => {
                setRightAudience(true);
                setLeftAudience(false);
              }}
              onMouseLeave={() => {
                setRightAudience(false);
              }}
            />
          </div>
          <div
            className="bottom-circle-div"
            style={{
              transform: PageProgress >= progress2 && "translateY(0%)",
            }}
          >
            <div
              className="bottom-imgs"
              style={{
                transformOrigin: rightAudience
                  ? "right"
                  : leftAudience && "left",
                transform: rightAudience
                  ? "translate(13vw,0%) scale(0.7)"
                  : leftAudience && "translate(-13vw,0%) scale(0.7)",
              }}
            >
              <img src={CenterCircle} alt="" className="joinus-circle-div" />
              <img className="green-overlay" src={GreenOverlay} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefit;
