import React from "react";
import "./terms.scss";
import ContactPopUp from "../components/ContactPopUp/ContactPopUp";

const PrivacyPolicy = ({ setPopUp, popUp,JoinusForm,formState,onClick }) => {
  window.scrollTo(0, 0);

  return (
    <div className="terms-section">
      {popUp && <ContactPopUp popUp={popUp} setPopUp={setPopUp} />}
      <p className="terms-heading">Privacy Policy</p>
      <p className="terms-para2">
        This privacy policy (“Policy”) relates to the manner ALPHAGAMMA MARKET
        VENTURES (“we”, “us”, “our”) in which we use, handle and process the
        data that you provide us in connection with using the products or
        services we offer. By using this website or by availing goods or
        services offered by us, you agree to the terms and conditions of this
        Policy, and consent to our use, storage, disclosure, and transfer of
        your information or data in the manner described in this Policy.
      </p>
      <br />
      <p className="terms-para2">
        We are committed to ensuring that your privacy is protected in
        accordance with applicable laws and regulations. We urge you to acquaint
        yourself with this Policy to familiarize yourself with the manner in
        which your data is being handled by us.
      </p><br/>
      <p className="terms-para2">
        ALPHAGAMMA MARKET VENTURES may change this Policy periodically and we
        urge you to check this page for the latest version of the Policy in
        order to keep yourself updated.
      </p>
      <p className="terms-heading2">What data is being collected</p>
      <p className="terms-subheading">
        We may collect the following information from you:
      </p>
      <ul>
        <li className="list-item">Name</li> <br />
        <li className="list-item">
          Contact information including address and email address.
        </li>{" "}
        <br />
        <li className="list-item">
          Demographic information or, preferences or interests.
        </li>{" "}
        <br />
        <li className="list-item">
          Personal Data or Other information relevant/ required for providing
          the goods or services to you.
        </li>{" "}
        <br />
        <li className="list-item">
          The meaning of Personal Data will be as defined under relevant Indian
          laws.
        </li>{" "}
        <br />
        <li className="list-item">
          You agree to pay us the charges associated with availing the Services.
        </li>{" "}
      </ul>{" "}
      <br />
      <p className="terms-para2">
        <span className="span-extra"> Note:</span> Notwithstanding anything under
        this Policy as required under applicable Indian laws, we will not be
        storing any credit card, debit card or any other similar card data of
        yours. Please also note that all data or information collected from you
        will be strictly in accordance with applicable laws and guidelines.
      </p>
      <div className="terms-heading2">What we do with the data we gather</div>
      <p className="terms-para2">
        We require this data to provide you with the goods or services offered
        by us including but not limited, for the below set out purposes:
      </p>
      <br />
      <ul>
        <li className="list-item">Internal record keeping.</li> <br />
        <li className="list-item">
          For improving our products or services.
        </li>{" "}
        <br />
        <li className="list-item">
          For providing updates to you regarding our products or services
          including any special offers.
        </li>{" "}
        <br />
        <li className="list-item">To communicate information to you.</li> <br />
        <li className="list-item">
          For internal training and quality assurance purposes.
        </li>
      </ul>
      {/* --------------------------- */}
      <div className="terms-heading2">Who do we share your data with</div>
      <p className="terms-para2">We may share your information or data with:</p>
      <br />
      <ul>
        <li className="list-item">
          Third parties including our service providers in order to facilitate
          the provisions of goods or services to you, carry out your requests,
          respond to your queries, fulfil your orders or for other operational
          and business reasons.
        </li>
        <br />
        <li className="list-item">
          With our group companies (to the extent relevant).
        </li>
        <br />
        <li className="list-item">
          Our auditors or advisors to the extent required by them for performing
          their services.
        </li>
        <br />
        <li className="list-item">
          Governmental bodies, regulatory authorities, law enforcement
          authorities pursuant to our legal obligations or compliance
          requirements.
        </li>
      </ul>
      <div className="terms-heading2">How we use cookies</div>
      <p className="terms-para2">
        We use "cookies" to collect information and to better understand
        customer behaviour. You can instruct your browser to refuse all cookies
        or to indicate when a cookie is being sent. However, if you do not
        accept cookies, you may not be able to avail our goods or services to
        the full extent. We do not control the use of cookies by third parties.
        The third party service providers have their own privacy policies
        addressing how they use such information.
      </p>
      <div className="terms-heading2">Your rights relating to your data</div>
      <p className="terms-para2-extra">
        <span>Right to Review -</span> You can review the data provided by you
        and can request us to correct or amend such data (to the extent
        feasible, as determined by us). That said, we will not be responsible
        for the authenticity of the data or information provided by you.
      </p>
      <br />
   
      <br />
      <p className="terms-para2-extra">
        <span>Withdrawal of your Consent -</span> You can choose not to provide
        your data, at any time while availing our goods or services or otherwise
        withdraw your consent provided to us earlier, in writing to our email
        ID: <span className="span-extra">info@alphagamma.in</span> In the event
        you choose to not provide or later withdraw your consent, we may not be
        able to provide you our services or goods.Please note that these rights
        are subject to our compliance with applicable laws.
      </p>
      <div className="terms-heading2">
        How long will we retain your information or data?
      </div>
      <p className="terms-para">
        We may retain your information or data (i) for as long as we are
        providing goods and services to you; and (ii) as permitted under
        applicable law, we may also retain your data or information even after
        you terminate the business relationship with us. However, we will
        process such information or data in accordance with applicable laws and
        this Policy.
      </p>
      <div className="terms-heading2">ata Security</div>
      <p className="terms-para">
        We will use commercially reasonable and legally required precautions to
        preserve the integrity and security of your information and data.
      </p>
      <div className="terms-heading2">Queries/ Grievance Officer</div>
      <p className="terms-para">
        For any queries, questions or grievances about this Policy, please
        contact us using the contact information provided on this website.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
