import React from "react";

import "./AnotherLayer.scss";


const profileSmall = "https://rentblob.blob.core.windows.net/agri-food-tech/Sahil_Img/IntentImgs/profile_small.png";
const newOnBoard = "https://rentblob.blob.core.windows.net/agri-food-tech/Sahil_Img/IntentImgs/newOnBoard.png";
const blackGreenDiv = "https://rentblob.blob.core.windows.net/agri-food-tech/Sahil_Img/IntentImgs/blackGreenDiv.png";
const profileMobile = "https://rentblob.blob.core.windows.net/agri-food-tech/Sahil_Img/IntentImgs/profileMobile.png";
const InterestMobile = "https://rentblob.blob.core.windows.net/agri-food-tech/Sahil_Img/IntentImgs/InterestMobile.png";
const greenStripText = "https://rentblob.blob.core.windows.net/agri-food-tech/Sahil_Img/IntentImgs/greenStripText.png";
// const IntentMobile = "https://rentblob.blob.core.windows.net/agri-food-tech/Sahil_Img/IntentImgs/IntentMobile.png";
const ProlifeSetupText = "https://rentblob.blob.core.windows.net/agri-food-tech/Sahil_Img/IntentImgs/ProlifeSetupText.png";

const AnotherLayer = ({ PageProgress, Progress }) => {
  const progress0 = Progress[8];
  const progress1 = Progress[9]; 
  const progress3 = Progress[10]; 
  const progress4 = Progress[11]; 
  // const progress5 = Progress[12]; 

  return (
    <div
      className="main-intent-page"
      style={{ 
        opacity: PageProgress >= progress0 && 1,
       }}
    >
      <div className="top-div-intent">
        <img
          src={blackGreenDiv}
          alt="black green"
          className="black-green"
          style={{
            zIndex: PageProgress >= progress1 && PageProgress < progress3 && 1,
            bottom: PageProgress >= progress4 && "-50vh",
            opacity: PageProgress >= progress0 && 1,
          }}
        />

        <div
          className="linearGrad-line"
          style={{
            width:
              PageProgress >= progress1 && PageProgress < progress4
                ? "88vw"
                : PageProgress >= progress4 && "0vw",
            background:
              PageProgress >= progress1 &&
              "linear-gradient(270deg, #0DF40F -10.3%, rgba(13, 244, 15, 0.00) 52.22%)",
            top: PageProgress >= progress3 && "42.5%",
            opacity: PageProgress >= progress0 && 1,
          }}
        ></div>

        <div className="smallest-mobile-container">
          <img
            src={profileSmall}
            alt="on board mobile small"
            className="on-board-mobile-small"
            style={{
              opacity: PageProgress >= progress3 && 1,
              transform:
              PageProgress >= progress3 && PageProgress < progress4 ? "translate(-30%,10%) rotate(-50deg)" :
                 PageProgress >= progress4 &&  "translate(60%,170%) rotate(0deg)",
            }}
          />
        </div>

        <div
          className="mobile-container"
          style={{
            transform:PageProgress >= progress0 && PageProgress < progress1 ? 'translate(-50%,-50%)' :
              PageProgress >= progress1 &&
              "translate(-105%,-55%) rotate(-20deg) scale(0.6)",
            opacity: PageProgress >= progress3 && 0,
          }}>
          <div
            className="text-profile-container"
          >
            <img
              src={ProlifeSetupText}
              alt="prolife text"
              className="prolife-text"
            />
          </div>
          <img
            src={profileMobile}
            alt="on board text"
            className="onBoard-mobile"
          />
        </div>

        <div
          className="mobile-container2"
          style={{
            transform: PageProgress >= progress1 && PageProgress < progress3
                ? "translate(-50%,-55%)"
                : PageProgress >= progress3 && PageProgress < progress4
                ? "translate(-80%,-64%) rotate(-30deg) scale(0.45)"
                : PageProgress >= progress4 && "translate(-50%,-150vh) scale(0.45)",
            opacity: PageProgress >= progress4 && progress4,
          }}
        >
          <img
            src={newOnBoard}
            alt="profile Mobile"
            className="profile-mobile"
          />
        </div>
      
        <img
          src={InterestMobile}
          alt="interest mobile"
          className="interest-mobile"
          style={{
            // left: PageProgress >= progress3 && "50%",
            transform: PageProgress >= progress3 &&  PageProgress < progress4 ? 'translate(-50%,-50%)'
              : PageProgress >= progress4 && "translate(-50%, -150vh) scale(0.55)",
            zIndex: PageProgress >= progress4 && 0,
            opacity: PageProgress >= progress4 && progress4,
          }}
        />
        <div className="greenStrip-container">
          <img
            src={greenStripText}
            alt="green Strip"
            className="green-strip-text"
            style={{
              transform:
                PageProgress >= progress3 && PageProgress < progress4
                  ? "translateX(0vw)"
                  : PageProgress >= progress4 && "translateX(50vw)",
              opacity: PageProgress >= progress4 && 0,
            }}
          />
        </div>

        {/* <img
          src={IntentMobile}
          alt="intent mobile"
          className="intent-mobile"
          style={{
            // left: PageProgress >= progress4 && "50%",
            transform:
              PageProgress >= progress4 && PageProgress < progress5
                ? "translate(-50%,-50%) rotate(0deg)"
                : PageProgress >= progress5 && "translate(-50%,-150vh)",
            opacity: PageProgress >= progress5 && progress5,
          }}
        /> */}
      </div>
    </div>
  );
};

export default AnotherLayer;