import React from "react";
import "./circularcard.scss";
const HandShake = "https://rentblob.blob.core.windows.net/agri-food-tech/sponsor/shaking-hand.png";

const CircularCard = ({ valSeven }) => {
 
  return (
    <div
      className="circular-card"
      style={{
        bottom: valSeven && "10%",
        opacity: valSeven && "1",
      }}
    >
    
      <div className="card-div">
        <div className="box" style={{ "--i": 1 }}>
          <img src={HandShake} alt="" />
        </div>
        <div className="box" style={{ "--i": 2 }}>
          <img src={HandShake} alt="" />
        </div>
        <div className="box" style={{ "--i": 3 }}>
          <img src={HandShake} alt="" />
        </div>
        <div className="box" style={{ "--i": 4 }}>
          <img src={HandShake} alt="" />
        </div>
        <div className="box" style={{ "--i": 5 }}>
          <img src={HandShake} alt="" />
        </div>
        <div className="box" style={{ "--i": 6 }}>
          <img src={HandShake} alt="" />
        </div>
        <div className="box" style={{ "--i": 7 }}>
          <img src={HandShake} alt="" />
        </div>
        <div className="box" style={{ "--i": 8 }}>
          <img src={HandShake} alt="" />
        </div>
        <div className="box" style={{ "--i": 9 }}>
          <img src={HandShake} alt="" />
        </div>
        <div className="box" style={{ "--i": 10 }}>
          <img src={HandShake} alt="" />
        </div>
        <div className="box" style={{ "--i": 11 }}>
          <img src={HandShake} alt="" />
        </div>
        <div className="box" style={{ "--i": 12 }}>
          <img src={HandShake} alt="" />
        </div>
        <div className="box" style={{ "--i": 13 }}>
          <img src={HandShake} alt="" />
        </div>
        <div className="box" style={{ "--i": 14 }}>
          <img src={HandShake} alt="" />
        </div>
      </div>
    </div>
  );
};

export default CircularCard;
