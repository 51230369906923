import React from 'react';

// images
import './video-card.scss';

export default function VideoCard(props) {
    return (
        <div className="video-card-main-container"
            style={{
                opacity: props.Visibe && '0'
            }}
        >
            <div className="video-card-main">
                <div className="frame-one-div video-frame">
                    <video
                        className='video-palyer'
                        autoPlay
                        muted
                        loop
                        playsInline

                    // preload ={true}
                    >
                        <source src={props.Video1} type="video/mp4" />
                    </video>
                </div>
                <div className="frame-two-div video-frame">
                    <video
                        className='video-palyer'
                        autoPlay
                        muted
                        loop
                        playsInline

                    // preload ={true}
                    >
                        <source src={props.Video2} type="video/mp4" />
                    </video>

                </div>
                <div className="frame-three-div video-frame">
                    <video
                        className='video-palyer'
                        autoPlay
                        muted
                        loop
                        playsInline

                    // preload ={true}
                    >
                        <source src={props.Video3} type="video/mp4" />
                    </video>
                </div>
                <div className="frame-four-div video-frame">
                    <video
                        className='video-palyer'
                        autoPlay
                        muted
                        loop
                        playsInline

                    // preload ={true}
                    >
                        <source src={props.Video4} type="video/mp4" />
                    </video>
                </div>
                <div className="frame-five-div video-frame">
                    <video
                        className='video-palyer'
                        autoPlay
                        muted
                        loop
                        playsInline

                    // preload ={true}
                    >
                        <source src={props.Video5} type="video/mp4" />
                    </video>
                </div>
                <div className="frame-six-div video-frame">
                    <video
                        className='video-palyer'
                        autoPlay
                        muted
                        loop
                        playsInline

                    // preload ={true}
                    >
                        <source src={props.Video6} type="video/mp4" />
                    </video>
                </div>
                <div className="frame-seven-div video-frame">
                    <video
                        className='video-palyer'
                        autoPlay
                        muted
                        loop
                        playsInline

                    >
                        <source src={props.Video7} type="video/mp4" />
                    </video>
                </div>
                <div className="frame-eight-div video-frame">
                    <video
                        className='video-palyer'
                        autoPlay
                        muted
                        loop
                        playsInline

                    // preload ={true}
                    >
                        <source src={props.Video8} type="video/mp4" />
                    </video>
                </div>


                <div className="hr-dots">
                    <hr className='hr1 hr-line' />
                    <hr className='hr2 hr-line' />
                    <hr className='hr3 hr-line' />
                    <hr className='hr4 hr-line' />
                    <hr className='hr5 hr-line' />
                    <hr className='hr6 hr-line' />
                    <hr className='hr7 hr-line' />


                </div>


            </div>

        </div>
    )
}
