import React, { useEffect } from 'react';


//styles
import './event-highlight.scss'

export default function EventHighlight() {

    useEffect(() => {
        const handleScrollEvent = (e) => {
            e.preventDefault()
        }
        window.addEventListener('wheel', handleScrollEvent, { passive: false })
        window.addEventListener('touchmove', handleScrollEvent, { passive: false })
        window.addEventListener('keydown', handleScrollEvent, { passive: false })


        return (() => {
            window.removeEventListener('wheel', handleScrollEvent)
            window.removeEventListener('touchmove', handleScrollEvent)
            window.removeEventListener('keydown', handleScrollEvent)

        })
    }, []);

    return (
        <div className="event-highlight-main-container">
            <img src='https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/event-highlights.png' alt="" />
            <span className='cross-icon'>&#10005;</span>
        </div>
    )
}
