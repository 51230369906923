import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";

//styles
import "./App.scss";

// components
import PhoneRotationComp from "./pages/components/Phone-Rotation-Comp/phone-rotation-comp";
// import GridBox from "./pages/components/GridBox/GridBox";
// import BackendForm from "./pages/components/Backend-Form/Backend-Form";

//pages
import HomePage from "./pages/home-page/home-page";
import AgendaPage from "./pages/home-page/CalendarOne/AgendaPage/AgendaPage";
import Footer from "./pages/components/Footer/Footer";
import Navbar from "./pages/components/Navbar/Navbar";
import ThankYou from "./pages/ThankYou/ThankYou";
import Terms from "./pages/TermsAndPrivacy/Terms";
import PrivacyPolicy from "./pages/TermsAndPrivacy/PrivacyPolicy";
import RefundPolicy from "./pages/TermsAndPrivacy/RefundPolicy";
import PopUpForm from "./pages/components/Form/PopUpForm";

function App() {
  // eslint-disable-next-line
  const [pathname, setPathname] = useState(window.location.pathname);
  const [formState, setFormState] = useState(false);
  const [popUp, setPopUp] = useState(false); // contact popip controller

  //media Querys
  const mediaQuery = window.matchMedia(`(max-width: 600px)`);
  const [query, setQuery] = useState(mediaQuery.matches);

  // useEffect(() => {
  //   window.onresize = () => {
  //     setQuery(mediaQuery.matches);
  //   };
  // }, [mediaQuery, query]);
  // const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setQuery(mediaQuery.matches);
      // setViewportWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [mediaQuery]);

  useEffect(() => {
    const handleResize = () => {
      window.location.reload();

    };
    // Attach the event listener
    window.addEventListener('orientationchange', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('orientationchange', handleResize);

    };
  }, [query]);

  const handlePathname = () => {
    setPathname(window.location.pathname);
  };

  const handleClickOnWhatsApp = () => {
    // Replace 'Hey!!' with your custom message
    const customMessage = encodeURIComponent("Hey!!");

    // Create the WhatsApp link
    const whatsappLink = `https://api.whatsapp.com/send?phone=${+919182184986}&text=${customMessage}`;

    // Open the link in a new tab
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="App">
      {query && (
        <div className="phone-rotation-indication-div">
          <PhoneRotationComp />
        </div>
      )}
      <div
        className="whats-app-div"
        onClick={handleClickOnWhatsApp}
        style={{
          backgroundImage:
            "url(https://rentblob.blob.core.windows.net/agri-food-tech/home-page/whatsapp-icon.png)",
        }}
      />
      {/* <p
        style={{
          position: 'fixed',
          top: '5%',
          left: '10%',
          color: '#fff',
          fontSize: '4vw',
          zIndex: '20'
        }}
      >{viewportWidth.toString()}</p> */}
      {/* {formState && <JoinusForm SetFormState={setFormState} />} */}

      <BrowserRouter>
        {formState && <PopUpForm SetFormState={setFormState} />}
        <Routes>
         
          <Route
            path="/"
            element={

              <HomePage HandlePathname={handlePathname} Query={query} />


            }
          />
          <Route
            path="/agenda"
            element={<AgendaPage HandlePathname={handlePathname} />}
          />
          <Route path="/aftc/thankyou" element={<ThankYou />} />
          <Route
            path="/privacy-policy"
            element={
              <>
                <Navbar popUp={popUp} setPopUp={setPopUp} />
                <PrivacyPolicy HandlePathname={handlePathname}
                  popUp={popUp}
                  setPopUp={setPopUp}
                />

                <Footer
                  SetFormState={setFormState}
                  LinkedinHref=
                  'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFSeisWL6b6XwAAAYxZLY5YcLzV_TK9UH-8Kb8M0mNJrE1WP2MZlwE40b6zQqhx4y-TJWoG14k1oWAtM6VKtAjrwb12lBKo4Bu1aBvIuifDJUz7Ck1jvWj8VfEkcXB3T2chXrk=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F96432571%2Fadmin%2Ffeed%2Fposts%2F'
                  FacebookHref=
                  'https://www.facebook.com/people/AlphaGamma-Market-Ventures/61554214913409/'
                  instagramHref=
                  'https://www.instagram.com/alphagamma.in/'
                />
              </>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <>
                <Navbar popUp={popUp} setPopUp={setPopUp} />
                <Terms HandlePathname={handlePathname}
                  popUp={popUp}
                  setPopUp={setPopUp} SetFormState={setFormState} />

                <Footer
                  SetFormState={setFormState}
                  LinkedinHref=
                  'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFSeisWL6b6XwAAAYxZLY5YcLzV_TK9UH-8Kb8M0mNJrE1WP2MZlwE40b6zQqhx4y-TJWoG14k1oWAtM6VKtAjrwb12lBKo4Bu1aBvIuifDJUz7Ck1jvWj8VfEkcXB3T2chXrk=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F96432571%2Fadmin%2Ffeed%2Fposts%2F'
                  FacebookHref=
                  'https://www.facebook.com/people/AlphaGamma-Market-Ventures/61554214913409/'
                  instagramHref=
                  'https://www.instagram.com/alphagamma.in/'
                />
              </>
            }
          />
          <Route
            path="/refund-policy"
            element={
              <>
                <Navbar popUp={popUp} setPopUp={setPopUp} />
                <RefundPolicy HandlePathname={handlePathname}
                  popUp={popUp}
                  setPopUp={setPopUp} />

                <Footer
                  SetFormState={setFormState}
                  LinkedinHref=
                  'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFSeisWL6b6XwAAAYxZLY5YcLzV_TK9UH-8Kb8M0mNJrE1WP2MZlwE40b6zQqhx4y-TJWoG14k1oWAtM6VKtAjrwb12lBKo4Bu1aBvIuifDJUz7Ck1jvWj8VfEkcXB3T2chXrk=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F96432571%2Fadmin%2Ffeed%2Fposts%2F'
                  FacebookHref=
                  'https://www.facebook.com/people/AlphaGamma-Market-Ventures/61554214913409/'
                  instagramHref=
                  'https://www.instagram.com/alphagamma.in/'
                />
              </>
            }
          />
          <Route
            path="/thankyou"
            element={<ThankYou />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
