import React, {useEffect} from 'react';
import "./contactpopus.scss";

const Linkedin = "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/linkdin.png"
const Instagram = "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/instagram.png"
const EMail = "https://rentblob.blob.core.windows.net/agri-food-tech/ai-imgs/email.png"

const ContactPopUp = ({setPopUp, popUp}) => {
    useEffect(() => {
        const handleScrollEvent = (e) => {
            // console.log(e)
            e.preventDefault()
        }
        window.addEventListener('wheel', handleScrollEvent, { passive: false })
        window.addEventListener('touchmove', handleScrollEvent, { passive: false })
        window.addEventListener('keydown', handleScrollEvent, { passive: false })


        return (() => {
            window.removeEventListener('wheel', handleScrollEvent)
            window.removeEventListener('touchmove', handleScrollEvent)
            window.removeEventListener('keydown', handleScrollEvent)

        })
    }, [])
  return (
    <div className='cpop-up'>
        <div className="cpopup-wrapper">
            <div className="cpop-close" onClick={() => setPopUp(!popUp)}>&#x292B;</div>
            <h2>CONTACT US</h2>
            <div className="cp-email">
                <p className='cp-para1'>Email</p>
                <p className="cp-para2">Info@Alphagamma.In</p>
            </div>
            <div className="cp-address">
                <div className="cpd-heading">Address</div>
                <div className="cp-full-details">
                T-Hub, Plot no 1/c, sy no 83/1, Raidurgram Panmaktha  Hyderabad Knowledge City, Serilingamaplly, Hyderabad, Telangana 500081
                </div>
            </div>
            <div className="social-links">
                <img src={Linkedin} alt="" />
                <img src={Instagram} alt="" />
                <img src={EMail} alt="" />
            </div>
        </div>
    </div>
  )
}

export default ContactPopUp;