import React from "react";
import "./terms.scss";

import ContactPopUp from "../components/ContactPopUp/ContactPopUp";

const RefundPolicy = ({ setPopUp, popUp }) => {
  window.scrollTo(0, 0);
  return (
    <>
      <div className="terms-section">
        {popUp && <ContactPopUp popUp={popUp} setPopUp={setPopUp} />}
        <p className="terms-heading">Refund Policy</p>
        <div className="terms-subheading">AlphaGamma Market Ventures</div>
        <div className="terms-para2-extra">
          <span>1. Introduction</span>
        </div>
        {/* <br/> */}
        <p className="terms-para">
          This Refund Policy applies to all services offered by{" "}
          <span className="span-extra">AlphaGamma Market Ventures</span>. By purchasing from us, you
          acknowledge and agree to the terms outlined in this policy.
        </p>
        <br />
        <div className="terms-para2-extra">
          <span>2. No Refund or Cancellation</span>
        </div>
        <p className="terms-para">
          <span className="span-extra">AlphaGamma Market Ventures</span> maintains a strict no refund and no
          cancellation policy. Once a purchase is made, it is final and
          non-refundable under any circumstances.
        </p>{" "}
        <br />
        <div className="terms-para2-extra">
          <span>3. Exceptions</span>
        </div>
        <p className="terms-para">
          No exceptions to this policy will be made, ensuring fairness and
          consistency for all customers.
        </p>
        <br />
        <div className="terms-para2-extra">
          <span>4. Contact Information</span>
        </div>
        {/* <br/> */}
        <p className="terms-para">
          For any inquiries or clarifications regarding this policy, please
          contact us.
        </p>
      </div>
    </>
  );
};

export default RefundPolicy;
